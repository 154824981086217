import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation

const Home = () => {

  useEffect(() => {
    // Optional: Add any effect here you want for animations
    // Example: Adding some scroll animations using libraries
  }, []);

  return (
    <div id="home" className="bg-gray-900 min-h-screen flex flex-col items-center justify-center text-white font-sans">
      {/* Hero Section */}
      <section className="text-center p-10">
        <h1 className="text-5xl md:text-6xl font-bold mb-4 animate__animated animate__fadeIn animate__delay-1s">
          Welcome to My Portfolio
        </h1>
        <p className="text-lg md:text-xl mb-6 animate__animated animate__fadeIn animate__delay-2s">
          I'm a passionate developer creating innovative and efficient solutions.
        </p>

        {/* Call to Action Buttons */}
        <div className="flex justify-center gap-6">
          <Link
            to="/about"   // Updated link for navigation
            className="px-6 py-3 bg-gray-700 rounded-full text-lg transform transition-transform hover:scale-110 hover:bg-gray-600 animate__animated animate__fadeIn animate__delay-3s"
          >
            About Me
          </Link>
          <Link
            to="/portfolio"  // Updated link for navigation
            className="px-6 py-3 bg-gray-700 rounded-full text-lg transform transition-transform hover:scale-110 hover:bg-gray-600 animate__animated animate__fadeIn animate__delay-3s"
          >
            Projects
          </Link>
        </div>
      </section>

      {/* Features Section */}
      <section className="bg-gray-800 text-white w-full py-20 flex justify-center items-center flex-col gap-8">
        <h2 className="text-3xl font-semibold animate__animated animate__fadeIn animate__delay-4s">
          What I Do
        </h2>
        <div className="flex flex-wrap justify-center gap-8">
          <div className="text-center max-w-xs p-6 bg-gray-700 rounded-xl shadow-lg transform transition-all hover:scale-105 hover:bg-gray-600 hover:shadow-2xl animate__animated animate__fadeIn animate__delay-5s">
            <h3 className="text-xl font-bold mb-3">App Development</h3>
            <p>Building applications using Flutter.</p>
          </div>
          <div className="text-center max-w-xs p-6 bg-gray-700 rounded-xl shadow-lg transform transition-all hover:scale-105 hover:bg-gray-600 hover:shadow-2xl animate__animated animate__fadeIn animate__delay-5s">
            <h3 className="text-xl font-bold mb-3">UI/UX Design</h3>
            <p>Designing modern and user-friendly interfaces.</p>
          </div>
          <div className="text-center max-w-xs p-6 bg-gray-700 rounded-xl shadow-lg transform transition-all hover:scale-105 hover:bg-gray-600 hover:shadow-2xl animate__animated animate__fadeIn animate__delay-5s">
            <h3 className="text-xl font-bold mb-3">Photography</h3>
            <p>Photography is my creative escape, where I capture emotions and moments through my lens. I specialize in portraits, finding joy in showcasing the beauty and uniqueness of every individual.</p>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="w-full bg-gray-800 text-white py-6 text-center mt-8">
        <p className="text-sm">© 2024 My Portfolio. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default Home;
