import React, { useState } from 'react';
import '../styles/Portfolio.css';

const Portfolio = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [currentScreenshotIndex, setCurrentScreenshotIndex] = useState(0);

  const projects = [
    {
      title: 'Safe Travel',
      image: '/project1.png',
      screenshots: ['/project1_1.jpg', '/project1_2.jpg','/project1_3.jpg','/project1_4.jpg'],
      figmaLink: 'https://www.figma.com/proto/hnrHcl53kioAp2DxkFaIWp/mini-project---safe-travel?node-id=31-20&node-type=canvas&t=ymQn8s8Ww17Zppst-1&scaling=scale-down&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=31%3A20',
      githubLink: 'https://github.com/aravinds-0484/safetravel',
    },
    {
      title: 'Project 2',
      image: '/project2.jpg',
      screenshots: ['/screenshot3.png', '/screenshot4.png'],
      figmaLink: 'https://www.figma.com/project-2',
      githubLink: 'https://github.com/yourusername/project-2',
    },
    {
      title: 'Project 3',
      image: '/project3.jpg',
      screenshots: ['/screenshot5.png', '/screenshot6.png'],
      figmaLink: 'https://www.figma.com/project-3',
      githubLink: 'https://github.com/yourusername/project-3',
    },
  ];

  const handleClick = (project) => {
    setSelectedProject(project);
    setCurrentScreenshotIndex(0);
  };

  const closeModal = () => {
    setSelectedProject(null);
  };

  const handleNext = () => {
    if (selectedProject) {
      setCurrentScreenshotIndex((prevIndex) =>
        (prevIndex + 1) % selectedProject.screenshots.length
      );
    }
  };

  const handlePrevious = () => {
    if (selectedProject) {
      setCurrentScreenshotIndex((prevIndex) =>
        (prevIndex - 1 + selectedProject.screenshots.length) %
        selectedProject.screenshots.length
      );
    }
  };

  return (
    <section id="portfolio" className="portfolio-section">
      <h2>My Projects</h2>
      <div className="portfolio-grid">
        {projects.map((project, index) => (
          <div key={index} className="portfolio-item" onClick={() => handleClick(project)}>
            <img src={project.image} alt={project.title} />
            <h3>{project.title}</h3>
          </div>
        ))}
      </div>

      {selectedProject && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            <h3><b>{selectedProject.title}</b></h3>
            <div className="carousel">
              <button className="carousel-btn" onClick={handlePrevious}>⟨</button>
              <div className="card">
                <img
                  src={selectedProject.screenshots[currentScreenshotIndex]}
                  alt={`Screenshot ${currentScreenshotIndex + 1}`}
                />
              </div>
              <button className="carousel-btn" onClick={handleNext}>⟩</button>
            </div>
            <div className="links">
              <a href={selectedProject.figmaLink} target="_blank" rel="noopener noreferrer">
                <img src="/figma-icon.svg" alt="Figma" className="icon" />
              </a>
              <a href={selectedProject.githubLink} target="_blank" rel="noopener noreferrer">
                <img src="/github-icon.svg" alt="GitHub" className="icon" />
              </a>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Portfolio;
