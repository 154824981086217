import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'; // Global styles
import App from './App'; // Main App component
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

const IndexPage = () => {
  const handleTap = () => {
    document.getElementById("home").scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="index-page" onClick={handleTap}>
      <div className="content">
        <h1>
          Hi all <span className="wave">👋</span>
        </h1>
        <h2>Myself Aravind S</h2>
      </div>
    </div>
  );
};

export default IndexPage;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
