import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import '../styles/Landing.css'; // Ensure correct path

const LandingPage = () => {

  useEffect(() => {
    const vantaEffect = window.VANTA.DOTS({
      el: "#landing-container",  // Target the element by its ID
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.00,
      minWidth: 200.00,
      scale: 1.00,
      scaleMobile: 1.00,
      color: 0x455c7c,  // Primary color
      color2: 0x20f4ff  // Secondary color
    });

    return () => {
      if (vantaEffect) {
        vantaEffect.destroy(); // Clean up Vanta.js effect
      }
    };
  }, []);


  return (
    <div id="landing-container" className="landing-page">
      <h1>
        Hi all <span className="wave">👋</span>
      </h1>
      <h2>Myself Aravind S</h2>

      {/* Scroll to Home Button */}
      <Link 
        to="/Home"  // This will route to the homepage ("/")
        className="fixed bottom-6 right-6 w-12 h-12 bg-gray-700 rounded-full flex items-center justify-center text-white text-2xl transform hover:scale-110 transition duration-300 ease-in-out"
      >
        go
      </Link>
    </div>
  );
};

export default LandingPage;
