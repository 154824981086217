import React from 'react';
import '../styles/AboutMe.css';

const AboutMe = () => {
  return (
    <section id="about" className="about-me">
      <div className="about-container">
      <img src='/mypic.jpg' alt="Profile" className="profile-image" />
        <div className="about-details">
          <h2>About Me</h2>
          <p>
            Hello! I'm Aravind, B.Tech student.
          </p>
          <a href="https://drive.google.com/file/d/1MepVIgPkthtCEEeblIvARo_61vA3MFxg/view?usp=drive_link" download className="cta-button">Download CV</a>
        </div>
      </div>
    </section>
  );
};

export default AboutMe;
